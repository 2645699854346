<template>
  <base-section id="we-help-your-success">
    <base-section-heading
      title="GALLERY"
      outlined
    />
    <!-- APPONE -->
    <v-container class="con1">
      <v-row>
        <v-col
          v-for="(appone,a) in appones"
          :key="a"
          cols="6"
          md="3"
          data-aos="flip-right"
          data-aos-duration="1500"
        >
          <v-img
            class="elevation-3"
            width="300px"
            height="200px"
            :src="appones[a]"
            color="grey lighten-1"
            tile
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- END -->
    <!-- APPTWO -->
    <v-container class="con1">
      <v-row>
        <v-col
          v-for="(apptwo,b) in apptwos"
          :key="b"
          cols="6"
          md="3"
          data-aos="flip-right"
          data-aos-duration="1500"
        >
          <v-img
            class="elevation-3"
            width="300px"
            height="200px"
            :src="apptwos[b]"
            color="grey lighten-1"
            tile
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- END -->
    <!-- APPTHREE -->
    <v-container class="con1">
      <v-row>
        <v-col
          v-for="(appthree,c) in appthrees"
          :key="c"
          cols="6"
          md="3"
          data-aos="flip-right"
          data-aos-duration="1500"
        >
          <v-img
            class="elevation-3"
            width="300px"
            height="200px"
            :src="appthrees[c]"
            color="grey lighten-1"
            tile
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- END -->
  </base-section>
</template>

<script>
  export default {
    name: 'Apps',

    data () {
      return {
        appones: [
          {
            src: require('@/assets/Barge+19.jpg'),
          },
          {
            src: require('@/assets/barge10.jpg'),
          },
          {
            src: require('@/assets/m&h.jpg'),
          },
          {
            src: require('@/assets/M&H_212.jpeg'),
          },
        ],
        apptwos: [
          {
            src: require('@/assets/m&hdrink.jpg'),
          },
          {
            src: require('@/assets/M&H_195.jpeg'),
          },
          {
            src: require('@/assets/M&H_46.jpeg'),
          },
          {
            src: require('@/assets/M&H_75.jpeg'),
          },
        ],
        appthrees: [
          {
            src: require('@/assets/BARGE_1.jpg'),
          },
          {
            src: require('@/assets/Barge+54.jpg'),
          },
          {
            src: require('@/assets/2541-054.jpg'),
          },
          {
            src: require('@/assets/MH51.jpg'),
          },
        ],
      }
    },
  }
</script>
